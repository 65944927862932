<template>
	<div>
		<!-- Toolbar -->
		<n-toolbar title="商品" left-text="返回" @clickLeft="$router.go(-1)" />
		<!-- content -->
		<div class="bg-light-blue-gray h-screen overflow-auto flex flex-col space-y-1s items-center relative pt-5 pb-24 px-3">
			<!-- header -->
			<p class="text-2xl font-medium">{{ catalogItem(id).product.name }}</p>
			<p class="text-xl font-medium">單位: {{ catalogItem(id).product.defaultUnit }}</p>
			<!-- product -->
			<div class="grid grid-cols-2 px-3 pb-20 h-full w-full">
				<div v-for="(unit, index) in units" :key="unit.text" class=" col-span-1 w-full h-56 p-2">
					<n-button color="muted" class="active:bg-primary active:text-white text-4xl font-bold" @click="selectUnit(index)">
						<div v-if="unit.value > 0" class="flex flex-col text-primary active:text-white space-y-3 text-3xl">
							<div>{{ unit.value }}</div>
							<div>{{ unit.text }}</div>
						</div>
						<div v-else>
							{{ unit.text }}
							</div>
					</n-button>
				</div>
			</div>

			<!-- footer -->
			<div class="z-10 fixed bottom-0 w-full text-xl p-5">
				<div class="flex justify-between pb-2 text-xl font-medium">
					<p>總計</p>	
					<p>{{ quantity }}{{ catalogItem(id).product.defaultUnit }}</p>
				</div>
				<n-button color="primary" class="p-2" @click="setAdjustmentProduct">更新</n-button>
			</div>
		</div>
		<!-- Modal -->
		<n-modal v-if="showModal">
			<div class="bg-white flex justify-center items-center text-2xl space-x-5 p-3">
				<input v-model="units[currentIndex].value " class="bg-gray text-center w-full placeholder-secondary font-medium rounded-lg py-2" placeholder="請輸入數量" disabled />
				<label class="text-md">{{ units[currentIndex].text }}</label>
			</div>
			<!-- NumberPad -->
			<n-number-pad :keys="['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'C']" @select="selectNumber"></n-number-pad>
			<n-button class="py-2 text-2xl" @click="unselectUnit">確認</n-button>
		</n-modal>
		<!-- Alert -->
		<n-alert v-if="showAlert" message="盤點資料已更新" hideCancel @ok="showAlert = false" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { unitConverter } from '@/utils'
import { AdjustmentProduct } from '@/models/adjustment'

export default {
	data() {
		return {
			id: this.$route.params.id,
			currentIndex: null,
			nonConvert:[
				{value:0, text: this.$store.getters.catalogItem(this.$route.params.id).product.defaultUnit}
			],
			units: [],
			convertUnits: [				
				{ value: 0, text: 'KG' },
				{ value: 0, text: '磅' },
				{ value: 0, text: '斤' },
				{ value: 0, text: '兩' },
			],
			showModal: false,
			showAlert: false,
		}
	},

	mounted() {
		this.units = this.convertData
		const index = this.currentAdjustment.products.findIndex(product => product.id === this.id)
		if (index < 0) return
		this.units = this.currentAdjustment.products[index].units
	},

	computed: {
		...mapGetters(['currentShop', 'currentAdjustment', 'catalogItem']),
		
		checkConvert() {
			let result = false
			this.convertUnits.find(convertUnit => convertUnit.text == this.catalogItem(this.id).product.defaultUnit) ? result = true : result = false
			return result
		},

		convertData() {
			let result = null
			this.convertUnits.find(convertUnit => convertUnit.text == this.catalogItem(this.id).product.defaultUnit) ? result = this.convertUnits : result = this.nonConvert
			return result
		},
		quantity() {
			let result = 0
			this.units.forEach(unit => (result += unitConverter(unit, this.catalogItem(this.id).product.defaultUnit)))
			return this.checkConvert ? Math.round(result * 10) / 10 : this.units[0].value
		},
	},

	methods: {
		selectUnit(index) {
			this.currentIndex = index
			this.showModal = true
		},

		unselectUnit() {
			this.currentIndex = null
			this.showModal = false
		},

		selectNumber(number) {
			if (number === '<') {
				const value = Number(String(this.units[this.currentIndex].value).slice(0, -1))
				this.units[this.currentIndex].value = value
				return
			} else if (number === 'C') {
				this.units[this.currentIndex].value = 0
				return
			} else if (number === '.' && String(this.units[this.currentIndex].value).includes('.')) {
				return
			} else if (number === '.') {
				const value = String(this.units[this.currentIndex].value) + '.'
				this.units[this.currentIndex].value = value
				return
			} else {
				const value = Number(String(this.units[this.currentIndex].value) + number)
				this.units[this.currentIndex].value = value
				return
			}
		},

		async setAdjustmentProduct() {
			try {
				await this.$store.dispatch('setLoading', { status: true })
				await this.$store.dispatch('setAdjustmentProduct', { adjustment: this.currentAdjustment, product: AdjustmentProduct(this.catalogItem(this.id), this.units, this.quantity) })  
				await this.$store.dispatch('setLoading', { status: false })
				return this.$router.go(-1)
			} catch (e) {
				console.error(e)
				await this.$store.dispatch('setError', { message: e.message, status: true })
				await this.$store.dispatch('setLoading', { status: false })
			}
		},
	},
}
</script>
