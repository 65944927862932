export function unitConverter(unit, targetUnit) {
	let result = 0
	switch (unit.text) {
		case 'KG': {
			switch (targetUnit) {
				case '磅': {
					result += unit.value * 2.204
					break
				}
				case '斤': {
					result += unit.value * 1.653
					break
				}
				case '兩': {
					result += unit.value * 26.45
					break
				}
				default: {
					result += unit.value
					break
				}
			}
			break
		}
		case '磅': {
			switch (targetUnit) {
				case 'KG': {
					result += unit.value * 0.4536
					break
				}
				case '斤': {
					result += unit.value * 0.75
					break
				}
				case '兩': {
					result += unit.value * 12
					break
				}
				default: {
					result += unit.value
					break
				}
			}
			break
		}
		case '斤': {
			switch (targetUnit) {
				case 'KG': {
					result += unit.value * 0.6048
					break
				}
				case '磅': {
					result += unit.value * 1.333
					break
				}
				case '兩': {
					result += unit.value * 16
					break
				}
				default: {
					result += unit.value
					break
				}
			}
			break
		}
		case '兩': {
			switch (targetUnit) {
				case 'KG': {
					result += unit.value * 0.0378
					break
				}
				case '磅': {
					result += unit.value * 0.0833
					break
				}
				case '斤': {
					result += unit.value * (1 / 16)
					break
				}
				default: {
					result += unit.value
					break
				}
			}
			break
		}
	}
	return result
}
